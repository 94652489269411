@import "./fonts.css";
html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'neue-haas-grotesk-display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.scroll-show::-webkit-scrollbar{
    display:initial;
}
.payment-method-dropdown .dropdown-list-menu::-webkit-scrollbar{
  display:initial;
}
.payment-method-dropdown .dropdown-list-menu {
  max-height: 74px;
}
.payment-method-height .dropdown-list-height{
  max-height: 120px;
}
*::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

*::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

*::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.15);
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 135, 139, 0.8);
}
