@font-face {
    font-family: 'Sofia Sans Condensed';
    src: url('../assets/fonts/SofiaSansCondensed-Black.woff2') format('woff2'),
        url('../assets/fonts/SofiaSansCondensed-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Sans Condensed';
    src: url('../assets/fonts/SofiaSansCondensed-Black.woff2') format('woff2'),
        url('../assets/fonts/SofiaSansCondensed-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Sans Condensed';
    src: url('../assets/fonts/SofiaSansCondensed-Bold.woff2') format('woff2'),
        url('../assets/fonts/SofiaSansCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Sans Condensed';
    src: url('../assets/fonts/SofiaSansCondensed-ExtraBold.woff2') format('woff2'),
        url('../assets/fonts/SofiaSansCondensed-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Sans Condensed';
    src: url('../assets/fonts/SofiaSansCondensed-ExtraLight.woff2') format('woff2'),
        url('../assets/fonts/SofiaSansCondensed-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Sans Condensed';
    src: url('../assets/fonts/SofiaSansCondensed-Light.woff2') format('woff2'),
        url('../assets/fonts/SofiaSansCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Sans Condensed';
    src: url('../assets/fonts/SofiaSansCondensed-Medium.woff2') format('woff2'),
        url('../assets/fonts/SofiaSansCondensed-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Sans Condensed';
    src: url('../assets/fonts/SofiaSansCondensed-Regular.woff2') format('woff2'),
        url('../assets/fonts/SofiaSansCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Sans Condensed';
    src: url('../assets/fonts/SofiaSansCondensed-Thin.woff2') format('woff2'),
        url('../assets/fonts/SofiaSansCondensed-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Sans Condensed';
    src: url('../assets/fonts/SofiaSansCondensed-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/SofiaSansCondensed-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter18pt-ExtraBold.woff2') format('woff2'),
        url('../assets/fonts/Inter18pt-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter18pt-Black.woff2') format('woff2'),
        url('../assets/fonts/Inter18pt-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter18pt-Bold.woff2') format('woff2'),
        url('../assets/fonts/Inter18pt-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter18pt-ExtraLight.woff2') format('woff2'),
        url('../assets/fonts/Inter18pt-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter18pt-Regular.woff2') format('woff2'),
        url('../assets/fonts/Inter18pt-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter18pt-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/Inter18pt-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter18pt-Thin.woff2') format('woff2'),
        url('../assets/fonts/Inter18pt-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter18pt-Light.woff2') format('woff2'),
        url('../assets/fonts/Inter18pt-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter18pt-Medium.woff2') format('woff2'),
        url('../assets/fonts/Inter18pt-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter24pt-ExtraBold.woff2') format('woff2'),
        url('../assets/fonts/Inter24pt-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter24pt-Bold.woff2') format('woff2'),
        url('../assets/fonts/Inter24pt-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter24pt-Black.woff2') format('woff2'),
        url('../assets/fonts/Inter24pt-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

